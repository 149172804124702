import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const Logout = () => {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session.data);
  //   const [privatePostRequests, { isLoading, error }] =
  //     usePrivatePostRequestsMutation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "session/clearSession" });
        window.location.href = "/";
      } catch (err) {
        console.log("err-->", err);
      }
    };
    fetchData();
  }, []);

  return <>Logging out....</>;
};
export default Logout;
