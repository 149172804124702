/** 
  All of the routes for the page layout of Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

const pageRoutes = [
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },
    ],
  },
];

// const pageRoutes = [
//   {
//     name: "pages",
//     columns: 3,
//     rowsPerColumn: 2,
//     collapse: [
//       {
//         name: "dashboards",
//         icon: <Icon>dashboard</Icon>,
//         collapse: [
//           {
//             name: "analytics",
//             route: "/dashboards/analytics",
//           },
//           {
//             name: "sales",
//             route: "/dashboards/metrics",
//           },
//         ],
//       },
//       {
//         name: "users",
//         icon: <Icon>people</Icon>,
//         collapse: [
//           {
//             name: "reports",
//             route: "/pages/users/reports",
//           },
//         ],
//       },
//       {
//         name: "extra",
//         icon: <Icon>queue_play_next</Icon>,
//         collapse: [
//           {
//             name: "pricing page",
//             route: "/pages/pricing-page",
//           },
//           { name: "RTL", route: "/pages/rtl" },
//           { name: "widgets", route: "/pages/widgets" },
//           { name: "charts", route: "/pages/charts" },
//           {
//             name: "notfications",
//             route: "/pages/notifications",
//           },
//         ],
//       },
//       {
//         name: "projects",
//         icon: <Icon>precision_manufacturing</Icon>,
//         collapse: [
//           {
//             name: "timeline",
//             route: "/pages/projects/timeline",
//           },
//         ],
//       },
//       {
//         name: "account",
//         icon: <Icon>account_balance</Icon>,
//         collapse: [
//           {
//             name: "settings",
//             route: "/pages/account/setting",
//           },
//           {
//             name: "billing",
//             route: "/pages/account/billing",
//           },
//           {
//             name: "invoice",
//             route: "/pages/account/invoice",
//           },
//         ],
//       },
//       {
//         name: "profile",
//         icon: <Icon>badge</Icon>,
//         collapse: [
//           {
//             name: "profile overview",
//             route: "/pages/profile/profile-overview",
//           },
//           {
//             name: "all projects",
//             route: "/pages/profile/all-projects",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     type: "collapse",
//     name: "Authentication",
//     key: "authentication",
//     icon: <Icon fontSize="medium">content_paste</Icon>,
//     collapse: [
//       {
//         name: "Sign In",
//         key: "sign-in",
//         collapse: [
//           {
//             name: "Basic",
//             key: "basic",
//             route: "/authentication/sign-in/basic",
//             component: <SignInBasic />,
//           },
//           {
//             name: "Cover",
//             key: "cover",
//             route: "/authentication/sign-in/cover",
//             component: <SignInCover />,
//           },
//           {
//             name: "Illustration",
//             key: "illustration",
//             route: "/authentication/sign-in/illustration",
//             component: <SignInIllustration />,
//           },
//         ],
//       },
//       {
//         name: "Sign Up",
//         key: "sign-up",
//         collapse: [
//           {
//             name: "Cover",
//             key: "cover",
//             route: "/authentication/sign-up/cover",
//             component: <SignUpCover />,
//           },
//         ],
//       },
//       {
//         name: "Reset Password",
//         key: "reset-password",
//         collapse: [
//           {
//             name: "Cover",
//             key: "cover",
//             route: "/authentication/reset-password/cover",
//             component: <ResetCover />,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: "application",
//     collapse: [
//       {
//         name: "kanban",
//         route: "/applications/kanban",
//         icon: "widgets",
//       },
//       {
//         name: "wizard",
//         route: "/applications/wizard",
//         icon: "import_contacts",
//       },
//       {
//         name: "data tables",
//         route: "/applications/data-tables",
//         icon: "backup_table",
//       },
//       {
//         name: "calendar",
//         route: "/applications/calendar",
//         icon: "event",
//       },
//     ],
//   },
//   {
//     name: "ecommerce",
//     columns: 2,
//     rowsPerColumn: 1,
//     collapse: [
//       {
//         name: "orders",
//         icon: <Icon>shopping_cart</Icon>,
//         collapse: [
//           {
//             name: "order list",
//             route: "/ecommerce/orders/order-list",
//           },
//           {
//             name: "order details",
//             route: "/ecommerce/orders/order-details",
//           },
//         ],
//       },
//       {
//         name: "products",
//         icon: <Icon>memory</Icon>,
//         collapse: [
//           {
//             name: "new product",
//             route: "/ecommerce/products/new-product",
//           },
//           {
//             name: "edit product",
//             route: "/ecommerce/products/edit-product",
//           },
//           {
//             name: "product page",
//             route: "/ecommerce/products/product-page",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: "docs",
//     collapse: [
//       {
//         name: "getting started",
//         href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
//         description: "All about overview, quick start, license and contents",
//         icon: <Icon>article</Icon>,
//       },
//       {
//         name: "foundation",
//         href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
//         description: "See our colors, icons and typography",
//         icon: <Icon>grading</Icon>,
//       },
//       {
//         name: "components",
//         href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
//         description: "Explore our collection of fully designed components",
//         icon: <Icon>apps</Icon>,
//       },
//       {
//         name: "plugins",
//         href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
//         description: "Check how you can integrate our plugins",
//         icon: <Icon>extension</Icon>,
//       },
//     ],
//   },
// ];

export default pageRoutes;
