import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import MDAlert from "components/MDAlert";
import MDSnackbar from "components/MDSnackbar";

export const ResponseHandler = (api) => {
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const [showSuccess, setShowSuccess] = useState(false);
  const toggleSnackbarSuccess = () => setShowSuccess(!showSuccess);

  useEffect(() => {
    if (api?.response?.error) {
      setShow(true);
    }
  }, [api?.response?.error]);

  useEffect(() => {
    if (api?.response?.data) {
      setShowSuccess(true);
    }
  }, [api?.response?.data]);

  if (api?.response?.data?.status === 200) {
    setTimeout(() => {
      setShow(false);
      setShowSuccess(false);
    }, 5000);
    return (
      <MDSnackbar
        color="success"
        icon="notifications"
        title="Server response"
        content={api?.response?.data?.message}
        dateTime="just now"
        open={showSuccess}
        close={toggleSnackbarSuccess}
      />
    );
  } else if (api?.response?.error) {
    setTimeout(() => {
      setShow(false);
      setShowSuccess(false);
    }, 5000);

    return (
      <MDSnackbar
        color="error"
        icon="notifications"
        title="Server response"
        content={api?.response?.error?.data?.message}
        dateTime="just now"
        open={show}
        close={toggleSnackbar}
      />
    );
  } else {
    return null;
  }
};
