import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { BooleanAPI } from "reducers/endpoints";
import session from "reducers/session";

const rootReducer = combineReducers({
  [BooleanAPI.reducerPath]: BooleanAPI.reducer,
  session: session,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [BooleanAPI.reducerPath, "user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }).concat(BooleanAPI.middleware),
});

const persistor = persistStore(store);

export { store, persistor };
