import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { LoginSocialGoogle } from "reactjs-social-login";
import logo from "assets/images/google.svg";

import { ResponseHandler } from "layouts/utils/response-handler";

import { usePrivatePostRequestsMutation } from "reducers/endpoints";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/mission_vision_banner.png";

//css color
import colors from "assets/theme/base/colors";
const { coloredShadows } = colors;

function Cover() {
  const [rememberMe, setRememberMe] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  let [privatePostRequests, { isLoading, error }] =
    usePrivatePostRequestsMutation();
  const dispatch = useDispatch();
  let [response, setResponse] = useState({});

  const handleSubmit = async () => {
    let userDetails = await privatePostRequests({
      route: "/auth/login",
      body: {
        email: email,
        password: password,
      },
    });
    setResponse(userDetails);
    if (userDetails?.data?.data) {
      dispatch({
        type: "session/setSession",
        payload: userDetails?.data?.data,
      });
      window.location.reload();
    }
  };

  const onResolve = async (item) => {
    let jsonData = item.data;
    let userDetails = await privatePostRequests({
      route: "/auth/google",
      body: jsonData,
    });
    console.log("userDetails", userDetails);
    setResponse(userDetails);
    if (userDetails?.data?.data) {
      dispatch({
        type: "session/setSession",
        payload: userDetails?.data?.data,
      });
      window.location.reload();
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          style={{ background: coloredShadows.booleanGradient }}
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                placeholder="************"
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : " sign in"}
              </MDButton>
            </MDBox>
            <MDBox mb={1}>
              <MDButton
                variant="gradient"
                color="dark"
                fullWidth
                disabled={isLoading}
              >
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GOOGLE_APP_ID}
                  onReject={() => {}}
                  onResolve={onResolve}
                  redirect_uri={`${process.env.REACT_APP_API_URL}/auth/google`}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={logo} alt="Google" width={18} height={18} />{" "}
                    &nbsp; &nbsp;
                    {isLoading ? "Loading, please wait" : "Sign in with google"}
                  </div>
                </LoginSocialGoogle>
              </MDButton>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <ResponseHandler response={response} />
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
